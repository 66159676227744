import React from "react"
import { slugifyText, nicefyDate } from "../../utils/utils"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import BoxAd from "../crumbs/boxAd"

const getCover = (article) => {
    if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
        return null
    } else {
        return article.cover.childImageSharp.fluid
    }
}

export default function LayoutNews({ article, relatedNews }) {
    const published = nicefyDate(article.publish)    
    const excerpt = <ReactMarkdown source={article.excerpt} />
    const paragraphs = article.content.replace(/\n\n/ig, '\n').split('\n').filter(_ => _.length > 0).map(p => <ReactMarkdown source={p} />)
    if (paragraphs.length >= 5) {
        const ad = <BoxAd isBlock={false} ad={ <iframe src="https://mindenpercben.hu/r/1213599.html" width="336" height="520" frameborder="0" scrolling="no"></iframe> } />
        paragraphs.splice(4, 0, ad)
    }
    const cover = getCover(article)
 
    return (
        <>
            <div className="category category_10" style={{ margin: `0px 0px 10px 0px` }}>
                <div className="container-fluid">
                    <span className="third">{ article.category.name }</span>
                </div>
            </div>
            <div className="content content-hu">
                <style type="text/css">
                    {`
                        .group h2 { font-size: 16px; margin-top: 5px; } .group p { font-size: 16px; margin-top: 5px; }  img.center { display: block; margin: 0 auto; } .content_fill a{ color: blue; } .hashtags{ position:relative; top:-10px; } .hashtags a{ color: #307da8; font-size: 14px; }
                    `}
                </style>
                <div className="hgroup"><h1>{ article.title }</h1></div>                
                <div className="content-info"><span>{ published } | { article.user.username }</span></div>
                <div className="lead">{ excerpt }</div>
                <div className="text">
                    <div className="post_image post_image_fixed post_image_center">
                        <div className="post_image_fill">
                            {cover && <Img fluid={article.cover.childImageSharp.fluid} alt={article.title} /> }
                            <span className="post_image_figcaption">{ article.title }</span>
                        </div>
                    </div>
                    <div className="section retraction" style={{ display: `block` }}>
                        <div className="content_fill">
                            { paragraphs }
                        </div>
						{
                        <BoxAd
                            ad={ <div data-gz-block="17f029ff-a000-419d-88fd-926b9e8b1133"></div> }
                        />
                    	}
                    </div>                    
                    <div className="addthis_inline_share_toolbox"></div>
                    <hr style={{ width: `100%`, color: `grey`, height: `2px`, backgroundColor: `grey` }} />
                    <div className="hashtags" style={{ textTransform: `uppercase` }}>
                        <ul style={{ listStyleType: `none`, padding: `0` }}>
                            { article.tags.map(tag => <li style={{ display: `inline`, marginRight: `10px` }}><Link to={`/kereses/tag/${slugifyText(tag.name)}/1`}>{ tag.name }</Link></li>) }
                        </ul>
                    </div>
                    {
                        <BoxAd
                            ad={ <iframe src="https://mindenpercben.hu/r/1213600.html" width="336" height="850" frameborder="0" scrolling="no"></iframe> }
                        />
                    }
                    <hr />
                    <div className="box-headline" style={{ margin: `5px 5px 10px 5px` }}>
                        <span style={{ color: `#18204c`, fontSize: `20px`, padding: `5px` }}>
                            <b>További híreink</b>
                        </span>
                    </div>
                    <div className="section retraction" style={{ display: `block` }}>
                        { relatedNews[0] }
                        { relatedNews[1] }
                        {
                            <BoxAd
                                ad={ <iframe src="https://mindenpercben.hu/r/netex275.html" width="336" height="280" frameborder="0" scrolling="no"></iframe> }
                            />
                        }
                        { relatedNews[2] }
                        { relatedNews[3] }
                        {
                            <BoxAd
                                ad={ <iframe src="https://mindenpercben.hu/r/ntdBnrId_8593.html" width="336" height="280" frameborder="0" scrolling="no"></iframe> }
                            />
                        }
                        { relatedNews[4] }
                        { relatedNews[5] }
                    </div>
                </div>
            </div>
            {
              <BoxAd
                ad={ <iframe src="https://mindenpercben.hu/r/1213607.html" width="336" height="480" frameborder="0" scrolling="no"></iframe> }
              />
            }
        </>
    )
}