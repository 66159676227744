import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import LayoutNews from "../components/layouts/layoutNews"
import { graphql } from "gatsby"
import Boxes from "./crumbs/boxes"
import ArticleSeo from "./crumbs/articleSeo"
import Ads from "./ads"

const getCoverUrl = (article) => {
    if (article.cover === null || article.cover.publicURL === null) {
        return ""
    } else {
        return article.cover.publicURL
    }
}

export default function Article({ pageContext, data }) {
    const article = data.allStrapiArticle.edges[0].node
    const canonical = "https://www.mindenpercben.hu" + pageContext.slug
    const canonicalMobile = "https://www.m.mindenpercben.hu" + pageContext.slug    
    const ogImage = "https://www.mindenpercben.hu" + getCoverUrl(article)

    const pageTitle = "Minden Percben | " + article.title
    const refreshUrl = pageContext.slug

    const relatedNews = data.related.edges.map(item => <Boxes box={1} article={item.node}/>)

    return (
        <>
            <ArticleSeo
                title={ pageTitle }
                description={ article.excerpt }
                keywords={ article.tags.map(_ => _.name).join(',') }
                canonical={ canonical }
                canonicalMobile={ canonicalMobile }
                cover={ ogImage }
                coverWidth="600"
                coverheight="320"
            />
            <LayoutSite 
                refreshUrl={refreshUrl}
                content={
                    <>
                        <LayoutNews
                            article={article}
                            relatedNews={relatedNews}
                        />
                    </>
                }
            />
        </>
    )
}

export const pageQuery = graphql`
    query GetArticle($articleID: String, $category: String) {
        allStrapiArticle(filter: {id: {eq: $articleID}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    content
                    tags {
                        name
                    }
                    user {
                        username
                    }
                    publish
                    cover {
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }

        related: allStrapiArticle(limit: 6, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: $category}}}) {
            edges {
                node {
                    title
                    category {
                        name
                    }
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`